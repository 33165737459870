























































































































































import Vue from 'vue'
import { Prop, Component, Mixins } from 'vue-property-decorator'
import { ModalFunctions } from '~/mixins/modals'

// @ts-ignore
import VueClipboard from 'vue-clipboard2'
import { IS_ELITEPAGE, IS_MARKETPLACE } from '~/const/environment'
Vue.use(VueClipboard)

@Component
export default class TermsOfService extends Mixins(ModalFunctions) {}
